export const FLEET_ADMIN = "fleet-admin";
export const MANAGE_ACCOUNT = "manage-account";
export const MANAGE_ACCOUNT_LINKS = "manage-account-links";
export const VIEW_PROFILE = "view-profile";
export const FLEET_INVENTORY = "fleet-inventory";
export const FLEET_DOCUMENTOS_ADMIN = "fleet-documentos-admin";
export const FLEET_LOCK_UNLOCK = "fleet-lock-unlock";
export const PEOPLE_ADMIN = "people-admin";
export const FLEET_BASIC = "fleet-basic";
export const FLEET_VEHICLE_EDITION = "fleet-vehicle-edition";
export const DEFAULT_ROLES_ADMIN = "default-roles-admin";
export const FLEET_VEHICLE_CREATION = "fleet-vehicle-creation";
export const FLEET_TRACK_VEHICLES = "fleet-track-vehicles";
export const FLEET_IOT_DELETION = "fleet-iot-deletion";
export const FINANCE_PAYOUT_STRIPE = "finance-payout-stripe";
export const FLEET_IOT_UNLINK = "fleet-iot-unlink";
export const FLEET_STREET_SUPPORT = "fleet-street-support";
export const OFFLINE_ACCESS = "offline_access";
export const FLEET_IOT_EDITION = "fleet-iot-edition";
export const FLEET_IOT_CREATION = "fleet-iot-creation";
export const UMA_AUTHORIZATION = "uma_authorization";
export const FLEET_INVENTORY_ADMIN = "fleet-inventory-admin";
export const FLEET_SUPPORT_CANCELLATION = "fleet-support-cancellation";
export const FLEET_VEHICLE_DELETION = "fleet-vehicle-deletion";
export const FLEET_USER_FACTORY = "fleet-user-factory";
export const FLEET_INVENTORY_MANAGER = "fleet-inventory-manager";
export const FLEET_RESOLVE_ALERTS = "fleet-resolve-alerts";
export const FLEET_MAKE_CHECKLIST = "fleet-make-checklist";
export const FLEET_LINK_IOT = "fleet-link-iot";
export const FLEET_JOURNEY_EDIT = "fleet-journey-edit";
export const FLEET_JOURNEY_READER = "fleet-journey-reader";
export const FLEET_PITSTOP = "fleet-pitstop";
export const FLEET_THIRD_ADMIN = "fleet-third-administrative";
export const FLEET_DELEGATION = "fleet-delegation";
export const FLEET_SEIZURE_FINISH = "fleet-seizure-finish";


export default {
    FLEET_ADMIN,
    MANAGE_ACCOUNT,
    MANAGE_ACCOUNT_LINKS,
    VIEW_PROFILE,
    FLEET_INVENTORY,
    FLEET_DOCUMENTOS_ADMIN,
    FLEET_LOCK_UNLOCK,
    PEOPLE_ADMIN,
    FLEET_BASIC,
    FLEET_VEHICLE_EDITION,
    DEFAULT_ROLES_ADMIN,
    FLEET_VEHICLE_CREATION,
    FLEET_TRACK_VEHICLES,
    FLEET_IOT_DELETION,
    FINANCE_PAYOUT_STRIPE,
    FLEET_IOT_UNLINK,
    FLEET_STREET_SUPPORT,
    OFFLINE_ACCESS,
    FLEET_IOT_EDITION,
    FLEET_IOT_CREATION,
    UMA_AUTHORIZATION,
    FLEET_INVENTORY_ADMIN,
    FLEET_SUPPORT_CANCELLATION,
    FLEET_VEHICLE_DELETION,
    FLEET_USER_FACTORY,
    FLEET_INVENTORY_MANAGER,
    FLEET_RESOLVE_ALERTS,
    FLEET_MAKE_CHECKLIST,
    FLEET_LINK_IOT,
    FLEET_JOURNEY_EDIT,
    FLEET_JOURNEY_READER,
    FLEET_PITSTOP,
    FLEET_THIRD_ADMIN,
    FLEET_DELEGATION,
    FLEET_SEIZURE_FINISH
};
