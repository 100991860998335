import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UsuarioData } from '../models/Usuario.model';
import { datadogRum } from '@datadog/browser-rum';
import packageJson from '../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class ObservabilityService {
  isProduction = environment.production;
  version = packageJson.version;
  private _startedObservability = false;

  constructor() {
  }

  init(user: UsuarioData, id: string): void {
    if (this.isProduction && !this._startedObservability) {
      if (typeof user === 'object') {
        this.initAndVerifyDatadog(user, id);
      }
      this._startedObservability = true;
    }
  }

  private initAndVerifyDatadog(user: UsuarioData, id: string) {
    datadogRum.init({
      applicationId: '58b53ac7-ece0-46f9-835f-2c73be0ef501',
      clientToken: 'pub9224ea40b586c9586feebb285d54f2cf',
      site: 'us5.datadoghq.com',
      service: 'monitor-front',
      env: 'prod',
      version: this.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 30,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: false,
      defaultPrivacyLevel: 'allow',
      storeContextsAcrossPages: true,
      trackSessionAcrossSubdomains: true,
      allowedTracingUrls: [/[A-Za-z09]+\.mottu\.cloud/g],
    });

    datadogRum.setUser({
      id,
      userIdV2: user.id.toString(),
      name: user.nome,
      email: user.email,
    });
  }
}
