import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SnackbarService } from "src/app/shared/services/snackBar.service";
import { InternalAuthService } from "src/app/shared/services/internalauth.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  public triageAPI = "maintenance-triage.mottu";

  constructor(
    private router: Router,
    private _snackBar: SnackbarService,
    private authService: InternalAuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleError(error);
        return throwError(error);
      })
    );
  }

  handleError(httpError: HttpErrorResponse | any) {
    const mensagemErro = httpError?.error?.mensagemErro;

    if (httpError?.url?.includes(this.triageAPI)) {
      return this.handleMessage(httpError.errorMessages[0]);
    }

    if (httpError?.status === 401) {
      this.handleMessage(mensagemErro);
      return this.authService.logoutAdmin().then(() => {});
    }

    if (httpError?.status === 500) {
      return mensagemErro
        ? this.handleMessage(mensagemErro)
        : this.handleMessage(httpError.statusText);
    }

    if (httpError?.status === 0) {
      return this.handleMessage(httpError.statusText);
    }

    if (httpError && httpError.error && httpError.error?.errors) {
      return this.handleMessage(httpError.error.errors.length > 0 ? httpError.error.errors[0].error : 'Houve algum problema interno, tente novamente mais tarde.');
    }
  }

  handleMessage(message: string) {
    this._snackBar.showSnackBar(message, "error");
  }

  handleResponseObject(responseObject: any) {
    if (responseObject.hasOwnProperty("mensagemErro"))
      return this.handleMessage(responseObject.mensagemErro);

    if (responseObject.hasOwnProperty("messageWithErrors"))
      return this.handleError(responseObject.messageWithErrors.toString());

    if (responseObject.hasOwnProperty("errorMessage"))
      return this.handleError(responseObject.errorMessage);

    if (responseObject.hasOwnProperty("traceId")) {
      var recordErrors = responseObject.errors as Record<string, any>;

      return Object.entries(recordErrors).forEach(([key, value]) => {
        value.forEach((message) => {
          this.handleError(message);
        });
      });
    }
  }
}
