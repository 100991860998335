import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { InternalAuthService } from "../../shared/services/internalauth.service";
import { FLEET_ADMIN } from "./utils/monitor-roles.constants";
import { Observable, of } from "rxjs";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivateChild {
  constructor(
    private authService: InternalAuthService,
    private router: Router,
    private keycloakService: KeycloakService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.checkUserRoles([FLEET_ADMIN]);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
        
    const hasAcess = this.authService.checkUserRoles([FLEET_ADMIN]);

    return hasAcess;
  }

  isAccessAllowed() {
    const hasAcess =  this.authService.checkUserRoles([FLEET_ADMIN]);

    return hasAcess;
  }


}
