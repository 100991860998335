import { Component, OnInit } from "@angular/core";
import { InternalAuthService } from "./shared/services/internalauth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { UsuarioLogged } from "./shared/models/Usuario.model";
import { VeiculoSuporte } from "./shared/models/ServicoVeiculoSuporte.model";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { AuthService as AdminAuthService } from "@mottu-ops/admin-utils";
import { Subject } from "rxjs";
import { UserAgentService } from "./shared/services/user-agent.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public title = "mottu-monitor";
  public userLogged: UsuarioLogged;
  public userVehicle: VeiculoSuporte;
  public isLoggedIn = false;

  componentName = new Subject();

  constructor(
    private authService: InternalAuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private keycloak: KeycloakService,
    private adminAuthService: AdminAuthService,
    private userAgentService: UserAgentService
  ) {
    this.matIconRegistry.addSvgIcon(
      "occ_guard",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/img/monitor_icone_vigia-02.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "occ_guard_black",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "../assets/img/monitor_icone_vigia-03.svg"
      )
    );

    this.userLogged = this.authService.userLogged?.dataResult;
    this.userVehicle = this.authService.veiculoLogado;
  }

  activatedRoute(event) {
    this.componentName.next(event?.constructor?.name);
  }

  private async updateToken(): Promise<void> {
    await this.keycloak.updateToken();

    const newToken = await this.keycloak.getToken();
    this.adminAuthService.credentials = {
      ...this.adminAuthService.credentials,
      accessToken: newToken,
    };
  }

  async ngOnInit() {
    this.getUserAgentData();
    // const url = window.location.href;
    // if (!skipAuth.some(skip => url.includes(skip))) {
    //   await this.authService.authorize();

    //   this.keycloak.keycloakEvents$.subscribe({
    //     next: async (event) => {
    //       if (event.type == KeycloakEventType.OnTokenExpired) {
    //         await this.updateToken();
    //       }
    //       if (
    //         event.type == KeycloakEventType.OnAuthRefreshError ||
    //         event.type == KeycloakEventType.OnAuthError
    //       ) {
    //         await this.keycloak.login();
    //       }
    //     },
    //   });

    //   let count = 0;
    //   this.componentName.subscribe((name) => {
    //     if (count == 0) {
    //       if (name != "LoginTemplateComponent") {
    //         if (this.userLogged) {
    //           this.observabilityService.init(this.userLogged.data);
    //         }
    //       }
    //     }
    //     count++;
    //   });
    // }


    const url = window.location.href;
    if (skipAuth.some(skip => url.includes(skip))) return;

    await this.authService.authorize();
    this.isLoggedIn = await this.keycloak.isLoggedIn();

		if (this.isLoggedIn) {
			const roles = this.keycloak.getUserRoles();
			const token = await this.keycloak.getToken();

			this.adminAuthService.credentials = {
				accessToken: token,
				roles
			};

			this.keycloak.keycloakEvents$.subscribe({
				next: async event => {
					if (event.type == KeycloakEventType.OnTokenExpired) {
						await this.updateToken();
					}
					if (event.type == KeycloakEventType.OnAuthRefreshError || event.type == KeycloakEventType.OnAuthError) {
						await this.keycloak.login();
					}
				},
			});
		} else {
			await this.keycloak.login();
		}
  }

  getUserAgentData() {
    this.userAgentService.saveUserAgentToLocalStorage();
  }
}

export const skipAuth = ['area-atendimento', 'transferencia-minha-mottu', 'minha-mottu-status-finalizacao', 'fagbot', 'formulario-pagamento', 'formulario-previsao-chegada', 'formulario-aceite-servico', 'formulario-acompanhamento-servico', 'acompanhamento-servico', 'formulario-cadastro-terceiro', 'assets/img/gps_blink.svg', 'confirm-email', 'terceiros-cadastro'];
