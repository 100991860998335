import { MonitorService } from "src/app/shared/services/monitor.service";
import { Inject, Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SignalRService } from "src/app/shared/services/signalR.service";
import { UsuarioPerfilEnum } from "../enum/UsuariosEnum";
import { BaseRequestResult } from "../models/BaseRequestResult.model";
import { UserSuporte } from "../models/ServicoVeiculoSuporte.model";
import { OneSignalService } from "./oneSignal.service";
import { ObservabilityService } from "./observability.service";
import { KeycloakService } from "keycloak-angular";
import {
  UsuarioSuporte,
  UsuarioSuporteModel,
} from "../models/PwaSuporte.model";
import { CurrentUser } from "../models/CurrentUser.model";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { SnackbarService } from "./snackBar.service";
import { UsuarioDataModel } from "../models/Usuario.model";
import { DOCUMENT } from "@angular/common";
import {
  FLEET_ADMIN,
  FLEET_PITSTOP,
  FLEET_THIRD_ADMIN,
} from "src/app/components/auth/utils/monitor-roles.constants";
import { UserAgentService } from "./user-agent.service";

@Injectable()
export class InternalAuthService {
  public currentUserSubject = new BehaviorSubject<boolean>(false);
  public currentUser;
  private baseUrl = environment.baseUrl;
  private baseUrlMonitor = environment.baseUrlMonitor;
  storages = [
    "isSupportUser",
    "boraboraUser",
    "currentUser",
    "isRenavamUser",
    "isFabricaUser",
    "isGuinchoUser",
    "NotificacoesSuporteRua",
    "NotificacoesRecolhimento",
    "boardParam",
    "mottuFieldApp",
  ];

  get isLoggedIn() {
    return this.currentUserSubject.asObservable();
  }

  get userLogged(): BaseRequestResult<CurrentUser<UsuarioSuporte>> {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  get boraboraUser() {
    return JSON.parse(localStorage.getItem("boraboraUser"));
  }

  get isSupportUser() : boolean {
    if (!this.userLogged) {
      return false;
    }
    const isSuportUser = JSON.parse(localStorage.getItem("isSupportUser"));
    return isSuportUser ?? false;
  }

  get isRenavamUser() {
    if (!this.userLogged) {
      return false;
    }
    return (
      this.userLogged.dataResult.data.perfilId == UsuarioPerfilEnum.Renavam
    );
  }

  get isFabricaUser() {
    if (!this.userLogged) {
      return false;
    }
    return (
      this.userLogged.dataResult.data.perfilId == UsuarioPerfilEnum.Fabrica
    );
  }

  get isGuichoUser() {
    if (!this.userLogged) {
      return false;
    }

    return (
      this.userLogged?.dataResult?.data?.perfilId == UsuarioPerfilEnum.Guincho || this.checkUserRoles([FLEET_THIRD_ADMIN])
    );
  }

  get veiculoLogado() {
    return JSON.parse(localStorage.getItem("veiculoSelecionado"));
  }

  get usuario(): string {
    const usuario = this.userLogged.dataResult?.data?.nome;
    if (usuario) return usuario;
    return "";
  }

  get currentUserLogged(): UsuarioDataModel {
    const user = this.userLogged.dataResult;
    return user ? (user.data as UsuarioDataModel) : null;
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private snackBarService: SnackbarService,
    private signalRService: SignalRService,
    private monitorService: MonitorService,
    private oneSignalService: OneSignalService,
    private observabilityService: ObservabilityService,
    private keycloak: KeycloakService,
    private userAgentService: UserAgentService
  ) {}

  public get currentUserValue(): any {
    if (this.currentUserSubject.value) {
      return this.currentUserSubject.value;
    }
  }
  login(user: any) {
    return this.http
      .post(this.baseUrlMonitor + "api/v1/IAM/authentication", user)
      .pipe(
        map((response: any) => {
          this.clearLocalStorage();
          if (response.dataResult) {
            this.setPlayerId(response.dataResult.data.id.toString());
            localStorage.setItem("currentUser", JSON.stringify(response));
            this.setRouteToRedirect(response.dataResult.data);
          }
          return response;
        })
      );
  }

  async authorize() {
    if (this.keycloak.isLoggedIn) {
      const roles = this.keycloak.getUserRoles();
      const token = await this.keycloak.getToken();
      const profile = await this.keycloak.loadUserProfile();

      return this.http
        .post(this.baseUrlMonitor + "api/v1/IAM/user", {
          email: profile.email,
          token: token,
        })
        .toPromise()
        .then((response: any) => {
          this.clearLocalStorage();
          if (response.dataResult) {
            this.setPlayerId(response.dataResult.data.id.toString());
            localStorage.setItem("currentUser", JSON.stringify(response));
            this.observabilityService.init(response.dataResult.data, profile.id);
          } else if (response.erro) {
            this.keycloak.logout(window.location.origin);
          }
        })
        .catch(() => {
          this.keycloak.logout(window.location.origin);
        });
    } else {
      this.keycloak.logout(window.location.href);
    }
  }

  setPlayerId(id) {
    try {
      var playerId = localStorage.getItem("playerId");

      if (playerId && playerId != "undefined") {
        this.oneSignalService.setExternalUserId(playerId, id).subscribe();
        localStorage.setItem("playerId", playerId);
      }
    } catch (error) {}
  }

  async redirect() {

    if (this.userLogged) {
      await this.setRouteToRedirect(this.userLogged.dataResult.data);
      const playerId = document.location.hash.split("=")[1];
      localStorage.setItem("playerId", playerId);
    }
  }

  async setRouteToRedirect(usuarioLogado: UsuarioSuporteModel) {

    if (await this.keycloak.isLoggedIn()) {

      const roles = this.keycloak.getUserRoles();
      const pitStopUser = roles?.includes(FLEET_PITSTOP);
      const adminUser = roles?.includes(FLEET_ADMIN);


      if (pitStopUser && !adminUser)
        return this.redirectLogin(["/home/pitstop"]);


      switch (usuarioLogado.perfilId) {
        case UsuarioPerfilEnum.Usuario:
          this.keycloak.logout(window.location.origin);
        case UsuarioPerfilEnum.AuxiliarRua:
        case UsuarioPerfilEnum.Suporte:
          localStorage.setItem("isSupportUser", JSON.stringify(true));
          return this.redirectLogin(["/veiculo-selecao"]);
        case UsuarioPerfilEnum.Delivery:
          localStorage.setItem("boraboraUser", JSON.stringify(true)); // Isso aqui ainda é usado?
          return this.redirectLogin(["/home/monitor"]);
        case UsuarioPerfilEnum.Portaria:
          return this.redirectLogin(["/movimentacao/base"]);
        case UsuarioPerfilEnum.Renavam:
          localStorage.setItem("isRenavamUser", JSON.stringify(true));
          return this.redirectLogin(["/home/unidades"]);
        case UsuarioPerfilEnum.Fabrica:
          localStorage.setItem("isFabricaUser", JSON.stringify(true));
          return this.redirectLogin(["/home/fabrica"]);
        case UsuarioPerfilEnum.Guincho:
        case UsuarioPerfilEnum.TerceiroAdmin:
          return this.redirecionaTerceiro(usuarioLogado);
        default:
          if (
            !usuarioLogado.perfis.includes(UsuarioPerfilEnum.Suporte) &&
            !usuarioLogado.perfis.includes(UsuarioPerfilEnum.AuxiliarRua) 
          )
            return this.redirectLogin(["/home/servicos-template/leaflet"]);
      }
    }
  }

  redirecionaTerceiro(usuarioLogado: UsuarioSuporteModel) {
    const isMobile = this.userAgentService.isMobile;

    if (
      (isMobile && this.checkUserRoles([FLEET_THIRD_ADMIN])) ||
      usuarioLogado.perfis.includes(UsuarioPerfilEnum.Guincho)
    ) {
      return this.redirectLogin(["/veiculo-selecao/guincho"]);
    } else if (
      usuarioLogado.perfis.includes(UsuarioPerfilEnum.TerceiroAdmin) &&
      !isMobile
    ) {
      return this.redirectLogin(["/terceiros"]);
    }
  }

  redirectLogin(page) {
    let mobileInterface = window["PWAMobile"];
    if (mobileInterface) {
      window["PWAMobile"].SetUserData(JSON.stringify(localStorage));
    }

    const returnUrl = this.route.snapshot.queryParams["returnUrl"];

    if (returnUrl) {

      this.router.navigateByUrl(returnUrl);
      return;
    }

    this.router.navigate(page);
  }

  resetSenha(credenciais) {
    return this.http.post(this.baseUrl + "mottuweb/resetSenha", credenciais);
  }

  async logout() {
    const isSupportUser = JSON.parse(localStorage.getItem("isSupportUser"));
    const SupportPerfis = [
      UsuarioPerfilEnum.Suporte,
      UsuarioPerfilEnum.TerceiroAdmin,
      UsuarioPerfilEnum.Guincho,
    ];
    const isSupportPerfil = SupportPerfis.includes(
      this.currentUserLogged?.perfilId
    );
    const isLogoutFromField = isSupportUser || isSupportPerfil;

    isLogoutFromField ? this.desvincularUsuarioPwa() : await this.logoutAdmin();
  }

  async logoutAdmin() {
    this.clearLocalStorage();
    this.signalRService.disconnectSignalR();
    this.currentUserSubject.next(false);
    await this.keycloak.logout(this.document.location.origin);
  }

  logoutField() {
    try {
      var playerId = localStorage.getItem("playerId");
      if (playerId && playerId != "undefined") {
        this.oneSignalService.setExternalUserId(playerId, "").subscribe();
      }
    } catch (error) {}
  }

  clearLocalStorage() {
    this.storages.forEach((storage) => {
      localStorage.removeItem(storage);
    });
  }

  checkUserRoles(roles: string[]) {
    return this.keycloak.getUserRoles().some((role) => roles.includes(role));
  }

  desvincularUsuarioPwa() {
    this.monitorService
      .desvincularUserVeiculo(this.userLogged.dataResult.data.id)
      .subscribe((response: BaseRequestResult<UserSuporte>) => {
        if (!response.erro) {
          this.logoutField();
          this.logoutAdmin();
        } else {
          this.snackBarService.showSnackBar(response.mensagemErro, "error");
        }
      });
  }
}
